import React from 'react';
import Menu from './Menu/menu';

const Layout = (props) => {
  return (
    <React.Fragment>
      <div className='layout-container  w-full'>
        <div>
          <Menu />
        </div>
        <div className='w-full'>{props.children}</div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
