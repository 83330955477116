import React from 'react'
import MechanicPage from './Mechanics'
import LoginPage from './Login'
import Layout from '../Layout'
import ProjectPage from './Projects'
import './index.css'

export const PageItems = {
    LOGIN: 1,
    MECHANIC: 2,
    PROJECTS: 3,
}

const Pages = (props) => {
    if (props.page === PageItems.LOGIN) {
        return <LoginPage />
    }

    let content = null

    if (props.page === PageItems.MECHANIC) {
        content = <MechanicPage />
    }

    if (props.page === PageItems.PROJECTS) {
        content = <ProjectPage />
    }
    return <Layout>{content}</Layout>
}

export default Pages
