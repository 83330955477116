import React, { useState, useContext } from 'react';
import ExpandableRow from '../ExpandableRow';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Project, Link, StateContext } from '../../StateContext';
import { deleteLink } from '../../Services/APICalls/apicalls';
import { IContextData } from '../../../App';
import WeekView, { totalHours } from './weekView';
import AddWeekOverview from './addWeekOverview';
import DeleteConfirmation from '../DeleteConfirmation';

interface IProps {
    link?: Link;
    project?: Project;
}

const LinkView = (props: IProps) => {
    const { loginData, setLoginData } = useContext<IContextData>(StateContext);
    const [open, setOpen] = useState(false);

    const handleClickOpen = (x) => {
        x.stopPropagation();
        setOpen(true);
    };

    const handleClose = (x) => {
        x.stopPropagation();
        setOpen(false);
    };

    const handleDeleteLink = (link: Link) => {
        deleteLink(link.id, setLoginData);
    };

    const handleConfirmDelete = (x) => {
        x.stopPropagation();
        handleDeleteLink(props.link);
        setOpen(false);
    };

    return (
        <div className=''>
            <ExpandableRow
                Title={props.link?.mechanic?.name || props.project.name}
                total={totalHours(props.link.weekData)}
                delButton={
                    <>
                        <IconButton
                            aria-label='delete'
                            size='small'
                            onClick={handleClickOpen}
                        >
                            <DeleteIcon />
                        </IconButton>
                        <DeleteConfirmation
                            handleDialogAbort={handleClose}
                            handleDialogConfirm={handleConfirmDelete}
                            dialogOpen={open}
                        />
                    </>
                }
                addWeek={<AddWeekOverview link={props.link} />}
            >
                <WeekView link={props.link} />
            </ExpandableRow>
        </div>
    );
};

export default LinkView;
