import React, { useContext, useState } from 'react';
import { Project, StateContext } from '../../StateContext';
import { Modal, Button, Box, TextField } from '@mui/material';
import { editMechanicCall } from '../../Services/APICalls/apicalls';
import { boxStyle } from '../Projects/styles';

interface PProps {
    about: string;
    name: string;
    id: number;
}

const EditMechanic = (props: PProps) => {
    const [mechanicName, setMechanicName] = useState(props.name);
    const [about, setAbout] = useState(props.about);
    const [open, setOpen] = useState(false);

    const { loginData, setLoginData } = useContext<any>(StateContext);

    const editMechanicHandler = () => {
        editMechanicCall(props.id, mechanicName, about, setLoginData);
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant='outlined' onClick={() => setOpen(true)}>
                Wijzigen
            </Button>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box style={boxStyle as any}>
                    <form
                        onSubmit={editMechanicHandler}
                        action='#'
                        className='flex flex-col items-center gap-3'
                    >
                        <TextField
                            type='text'
                            variant='filled'
                            label='Monteur'
                            value={mechanicName}
                            onChange={(x) => setMechanicName(x.target.value)}
                        ></TextField>
                        <TextField
                            id='filled-multiline-static'
                            label='Omschrijving'
                            multiline
                            rows={4}
                            defaultValue='Type hier bijzonderheden'
                            variant='filled'
                            style={{ width: '220px' }}
                            value={about}
                            onChange={(x) => setAbout(x.target.value)}
                        ></TextField>
                        <button style={{ width: '220px' }}>Wijzigen</button>
                    </form>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default EditMechanic;
