import axios from 'axios';
import furball from 'wa-furball';
import { fetchAllData } from '../../../utils';
import { Mechanic } from '../../StateContext';
import { Project } from '../../StateContext';
import { Urls } from '../../../Urls';

export const loginCall = (userName, password, setLoginData) => {
    axios
        .post(`${Urls.BaseApiUrl}/Auth`, {
            username: userName,
            password: password,
        })
        .then((response) => {
            furball.success(`${response.data.user.userName} is ingelogd.`);
            setLoginData(response.data);
        })
        .catch((error) => {
            furball.error('Inloggen mislukt.');
        });
};

export const CheckIfAlreadyLoggedIn = (setLoginData) => {
    axios
        .get(`${Urls.BaseApiUrl}/Auth`)
        .then((response) => {
            furball.success(`${response.data.user.userName} is ingelogd.`);
            setLoginData(response.data);
        })
        .catch((error) => {
            // If error that means user weren't logged in
            // furball.error('Inloggen mislukt.');
        });
};

export const addMechanicCall = (mechanicName, about, type, setLoginData) => {
    axios
        .post(`${Urls.BaseApiUrl}/Mechanic`, {
            name: mechanicName,
            about: about,
            type: parseInt(type as string),
        })
        .then((response) => {
            furball.success(`${response.data.name} is toegevoegd.`);
            fetchAllData(setLoginData);
        })
        .catch((error) => {
            furball.error('Toevoegen mislukt.');
        });
};

export const editMechanicCall = (id, mechanicName, about, setLoginData) => {
    axios
        .put(`${Urls.BaseApiUrl}/Mechanic/${id}`, {
            name: mechanicName,
            about: about,
        })
        .then((response) => {
            furball.success(`${response.data.name} is gewijzigd.`);
            fetchAllData(setLoginData);
        })
        .catch((error) => {
            furball.error('Wijzgen mislukt.');
        });
};

export const editWeekDataCall = (
    id,
    weekId,
    secondsWorked,
    weekNumber,
    setLoginData
) => {
    axios
        .put(`${Urls.BaseApiUrl}/links/${id}/week/${weekId}`, {
            secondsWorked: secondsWorked,
            weekNumber: weekNumber,
        })
        .then((response) => {
            furball.success(`Week ${weekNumber} is gewijzigd.`);
            fetchAllData(setLoginData);
        })
        .catch((error) => {
            furball.error('Wijzgen mislukt.');
        });
};

export const connectMechanicCall = (projectId, mechanicId, setLoginData) => {
    console.log(projectId, 'Connect Method');
    axios
        .post(`${Urls.BaseApiUrl}/links/${projectId}/${mechanicId}`)
        .then((response) => {
            furball.success(`Monteur is gekoppeld.`);
            fetchAllData(setLoginData);
        })
        .catch((error) => {
            furball.error('Koppelen mislukt.');
        });
};

export const deleteLink = (linkId, setLoginData) => {
    axios
        .delete(`${Urls.BaseApiUrl}/links/${linkId}`)
        .then(() => {
            furball.success(`Koppeling is verwijderd.`);
            fetchAllData(setLoginData);
        })
        .catch((error) => {
            furball.error('Verwijderen mislukt.');
        });
};

export const addProjectCall = (projectName, description, setLoginData) => {
    axios
        .post(`${Urls.BaseApiUrl}/Project`, {
            name: projectName,
            about: description,
        })
        .then((response) => {
            furball.success(`${response.data.name} is toegevoegd.`);
            fetchAllData(setLoginData);
        })
        .catch((error) => {
            furball.error('Toevoegen mislukt.');
        });
};

export const addWeekCall = (
    linkId,
    weekNumber,
    secondsWorked,
    setLoginData
) => {
    axios
        .post(`${Urls.BaseApiUrl}/links/${linkId}/week/${weekNumber}`, {
            secondsWorked: secondsWorked,
        })
        .then((response) => {
            furball.success(`Week ${weekNumber} is toegevoegd.`);
            fetchAllData(setLoginData);
        })
        .catch((error) => {
            furball.error('Toevoegen mislukt.');
        });
};

export const editProjectCall = (id, projectName, description, setLoginData) => {
    axios
        .put(`${Urls.BaseApiUrl}/Project/${id}`, {
            name: projectName,
            about: description,
        })
        .then((response) => {
            furball.success(`${response.data.name} is gewijzigd.`);
            fetchAllData(setLoginData);
        })
        .catch((error) => {
            furball.error('Wijzigen mislukt.');
        });
};

export const deleteMechanicCall = (m: Mechanic, setLoginData) => {
    axios
        .delete(`${Urls.BaseApiUrl}/Mechanic/${m.id}`)
        .then(() => {
            furball.success(`${m.name} is verwijderd.`);
            fetchAllData(setLoginData);
        })
        .catch((error) => {
            furball.error('Verwijderen mislukt.');
        });
};

export const deleteProjectCall = (p: Project, setLoginData) => {
    axios
        .delete(`${Urls.BaseApiUrl}/Project/${p.id}`)
        .then(() => {
            furball.success(`${p.name} is verwijderd.`);
            fetchAllData(setLoginData);
        })
        .catch((error) => {
            furball.error('Verwijderen mislukt.');
        });
};

export const logoutCall = (userName) => {
    axios.post(`${Urls.BaseApiUrl}/Auth/logout`);
    furball.info(`${userName} is uitgelogd`);
};

export const registerUserCall = (userName, password, setLoginData) => {
    axios
        .post('https://hourtracker.sindrema.com/Auth/create', {
            username: userName,
            password: password,
        })
        .then((response) => {
            furball.success(`${response.data.user.userName} is aangemaakt.`);
            setLoginData(response.data);
        })
        .catch((error) => {
            furball.error('Registreren mislukt.');
        });
};
