import React from 'react';
import AddProject from './addProject';
import ProjectList from './projectList';

const ProjectPage = () => {
    return (
        <div className='flex flex-col  w-full'>
            <div className='flex flex-row justify-between'>
                <div>Projecten</div>
                <AddProject />
            </div>
            <div className='flex'>
                <ProjectList />
            </div>
        </div>
    );
};

export default ProjectPage;
