import React, { useContext, useState } from 'react';
import { StateContext } from '../../StateContext';
import axios from 'axios';
import furball from 'wa-furball';
import { fetchAllData } from '../../../utils';
import {
    Modal,
    Button,
    Box,
    TextField,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
} from '@mui/material';
import { boxStyle } from '../Projects/styles';
import { Urls } from '../../../Urls';
import { addMechanicCall } from '../../Services/APICalls/apicalls';

export const MechanicTypes = [
    { value: 0, label: 'Monteur', weight: 100 },
    { value: 1, label: 'Hulpmonteur', weight: 80 },
    { value: 2, label: 'Hulp', weight: 20 },
    { value: 3, label: 'Kantoor', weight: 100 },
];

const AddMechanic = () => {
    const [mechanicName, setMechanicName] = useState('');
    const [type, setType] = useState<string | number>(MechanicTypes[0].value);
    const [about, setAbout] = useState('');
    const [open, setOpen] = useState(false);

    const { loginData, setLoginData } = useContext(StateContext);

    const addMechanicHandler = () => {
        addMechanicCall(mechanicName, about, type, setLoginData);
        setOpen(false);
        setMechanicName('');
        setAbout('');
        return;
    };

    return (
        <React.Fragment>
            <Button
                variant='outlined'
                color='success'
                onClick={() => setOpen(true)}
            >
                Monteur toevoegen
            </Button>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box style={boxStyle}>
                    <form
                        onSubmit={addMechanicHandler}
                        action='#'
                        className='flex flex-col items-center gap-3'
                    >
                        <TextField
                            type='text'
                            label='Naam'
                            style={{ width: '220px' }}
                            value={mechanicName}
                            onChange={(x) => setMechanicName(x.target.value)}
                        ></TextField>
                        <div>
                            <FormControl style={{ width: '220px' }}>
                                <InputLabel id='mechanic-type-select-label'>
                                    Type
                                </InputLabel>
                                <Select
                                    label='Type'
                                    labelId='mechanic-type-select-label'
                                    id='mechanic-type-select'
                                    value={type}
                                    onChange={(x) => setType(x.target.value)}
                                >
                                    {MechanicTypes.map((type) => (
                                        <MenuItem
                                            key={type.value}
                                            value={type.value}
                                        >
                                            {type.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div>
                            <TextField
                                style={{ width: '220px' }}
                                type='text'
                                id='outlined-multiline-static'
                                label='Notities'
                                multiline
                                rows={4}
                                defaultValue='Type hier bijzonderheden'
                                value={about}
                                onChange={(x) => setAbout(x.target.value)}
                            ></TextField>
                        </div>
                        <button
                            style={{ width: '220px' }}
                            onClick={addMechanicHandler}
                        >
                            Toevoegen
                        </button>
                    </form>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default AddMechanic;

// setLoginData({
//   ...loginData,
//   mechanics: [...loginData.mechanics, response.data],
// }
