import React, { useContext, useState } from 'react';
import { StateContext } from '../../StateContext';
import { Modal, Box, TextField } from '@mui/material';
import { boxStyle } from '../Projects/styles';
import { registerUserCall } from '../../Services/APICalls/apicalls';
import PasswordField from './password';

const RegisterUser = () => {
    const [open, setOpen] = useState(false);
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [userName, setUserName] = useState('');

    const { loginData, setLoginData } = useContext(StateContext);

    const registerUserHandler = () => {
        if (password1 === password2) {
            registerUserCall(userName, password1, setLoginData);
            setUserName('');
            setPassword1('');
            setPassword2('');
        } else {
            return alert('Wachtwoorden komen niet overeen.');
        }
    };

    return (
        <React.Fragment>
            <button onClick={() => setOpen(true)} className='w-100'>
                Registreren
            </button>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box style={boxStyle}>
                    <form
                        onSubmit={registerUserHandler}
                        action='#'
                        className='flex flex-col justify-center items-center gap-3'
                    >
                        <div>
                            <TextField
                                type='text'
                                variant='standard'
                                label='Gebruikersnaam'
                                value={userName}
                                onChange={(x) => setUserName(x.target.value)}
                            ></TextField>
                        </div>
                        <PasswordField
                            value={password1}
                            onChange={setPassword1}
                        />
                        <PasswordField
                            value={password2}
                            onChange={setPassword2}
                        />
                        <button
                            onClick={registerUserHandler}
                            style={{ width: '200px' }}
                        >
                            Registreren
                        </button>
                    </form>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default RegisterUser;
