import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material';

interface IProps {
    dialogOpen: boolean;
    handleDialogConfirm: (x: any) => void;
    handleDialogAbort: (x: any) => void;
}

const DeleteConfirmation = (props: IProps) => {
    return (
        <>
            <Dialog
                open={props.dialogOpen}
                onClose={props.handleDialogAbort}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <div className='bg-gray flex flex-col items-center'>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            Weet je zeker dat je dit wilt verwijderen. Dit kan
                            niet worden terug gedraaid.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button
                            className='bg-darkgray'
                            style={{ width: '220px' }}
                            onClick={props.handleDialogConfirm}
                        >
                            Bevestigen
                        </button>
                        <button
                            className='bg-darkgray'
                            style={{ width: '220px' }}
                            onClick={props.handleDialogAbort}
                        >
                            Annuleren
                        </button>
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
};

export default DeleteConfirmation;
