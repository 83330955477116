import React, { useContext, useState } from 'react';
import { StateContext } from '../../StateContext';
import {
    Modal,
    Button,
    Box,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from '@mui/material';
import { boxStyle } from './styles';
import { addProjectCall } from '../../Services/APICalls/apicalls';

export const ProjectTypes = [
    { value: 0, label: 'Badkamer' },
    { value: 1, label: 'Keuken' },
    { value: 2, label: 'Toilet' },
];

const AddProject = () => {
    const [projectName, setProjectName] = useState('');
    const [type, setType] = useState<string | number>(ProjectTypes[0].value);
    const [description, setDescription] = useState('');
    const [open, setOpen] = useState(false);

    const { loginData, setLoginData } = useContext<any>(StateContext);

    const addProjectHandler = () => {
        addProjectCall(projectName, description, setLoginData);
        setOpen(false);
        setProjectName('');
        setDescription('');
    };

    return (
        <div>
            <React.Fragment>
                <Button
                    variant='outlined'
                    color='success'
                    onClick={() => setOpen(true)}
                >
                    Project toevoegen
                </Button>
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                >
                    <Box style={boxStyle as any}>
                        <form
                            onSubmit={addProjectHandler}
                            action='#'
                            className='flex flex-col items-center gap-3'
                        >
                            <TextField
                                type='text'
                                label='Project'
                                value={projectName}
                                onClick={(x) => {
                                    x.stopPropagation();
                                }}
                                onChange={(x) => setProjectName(x.target.value)}
                            ></TextField>
                            <div>
                                <FormControl style={{ width: '220px' }}>
                                    <InputLabel id='mechanic-select-label'>
                                        Type project
                                    </InputLabel>
                                    <Select
                                        labelId='project-type-select-label'
                                        id='project-type-select'
                                        label='Type project'
                                        value={type}
                                        onChange={(x) =>
                                            setType(x.target.value)
                                        }
                                    >
                                        {ProjectTypes.map((type) => (
                                            <MenuItem
                                                key={type.value}
                                                value={type.value}
                                            >
                                                {type.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <TextField
                                style={{ width: '220px' }}
                                id='outlined-multiline-static'
                                label='Bijzonderheden'
                                multiline
                                rows={4}
                                defaultValue='Type hier bijzonderheden'
                                value={description}
                                onChange={(x) => setDescription(x.target.value)}
                            />
                            <button
                                style={{ width: '220px' }}
                                onClick={addProjectHandler}
                            >
                                Toevoegen
                            </button>
                        </form>
                    </Box>
                </Modal>
            </React.Fragment>
        </div>
    );
};

export default AddProject;
