import React from 'react'
import AddMechanic from './addMechanic'
import MechanicList from './mechanicList'

const MechanicPage = () => {
	return (
		<div className='flex flex-col'>
			<div className='flex flex-row justify-between'>
				<div>Monteurs</div>
				<AddMechanic />
			</div>
			<div className='flex w-100'>
				<MechanicList />
			</div>
		</div>
	)
}

export default MechanicPage
