import React, { useContext, useEffect, useRef, useState } from 'react';
import { StateContext } from '../../StateContext';
import axios from 'axios';
import './login.css';
import PasswordField from './password';
import { TextField } from '@mui/material';
import { loginCall } from '../../Services/APICalls/apicalls';
import RegisterUser from './register';
axios.defaults.withCredentials = true;

const LoginPage = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const { loginData, setLoginData } = useContext(StateContext);

    const loginHandler = async (e) => {
        loginCall(userName, password, setLoginData);
        setPassword('');
    };

    return (
        <div className='flex flex-col items-center justify-center'>
            <img src="https://www.j-twee.nl/wp-content/themes/jtwee/img/logo.png"/>
            <br />
            <h2></h2>
            <div style={{ width: '200px' }}>
                <form className='login-form' onSubmit={loginHandler} action='#'>
                    <TextField
                        label='Gebruikersnaam'
                        type='text'
                        variant='standard'
                        value={userName}
                        onChange={(x) => setUserName(x.target.value)}
                    ></TextField>
                    <PasswordField value={password} onChange={setPassword} />
                    <button className='w-100'>Inloggen</button>
                </form>
                <RegisterUser />
            </div>
        </div>
    );
};

export default LoginPage;
