import './App.css';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { ILoginData, StateContext } from './Components/StateContext';
import Pages, { PageItems } from './Components/Pages';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { CheckIfAlreadyLoggedIn } from './Components/Services/APICalls/apicalls';

export interface IContextData {
    loginData: ILoginData;
    setLoginData: Function;
    page: number;
    setPage: Function;
}

function App() {
    const [loginData, setLoginData] = useState<ILoginData>(null);
    const [page, setPage] = useState(PageItems.PROJECTS);

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const mounted = useRef(false);

    // On mount, aka on first load
    useEffect(() => {
        if (mounted.current) return;
        CheckIfAlreadyLoggedIn(setLoginData);
        mounted.current = true;
    }, []);

    return (
        <ThemeProvider theme={darkTheme}>
            <div className='App'>
                <link href='./output.css' rel='stylesheet'></link>
                <StateContext.Provider
                    value={
                        {
                            loginData,
                            setLoginData,
                            page,
                            setPage,
                        } as IContextData
                    }
                >
                    <header className='App-header'>
                        <Pages page={!loginData ? PageItems.LOGIN : page} />
                    </header>
                </StateContext.Provider>
            </div>
        </ThemeProvider>
    );
}

export default App;
