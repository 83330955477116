import React, { useContext, useState } from 'react';
import { Project, StateContext } from '../../StateContext';
import ExpandableRow from '../../General/ExpandableRow';
import ExpendableBox from '../../General/ExpandableBox';
import { deleteProjectCall } from '../../Services/APICalls/apicalls';
import EditProject from './editProject';
import ConnectMechanic from './connectMechanic';
import LinkView from '../../General/Links/linkView';
import { totalProjectHours } from '../../General/Links/weekView';
import { IContextData } from '../../../App';

const projectList = () => {
    const { loginData, setLoginData } = useContext<IContextData>(StateContext);

    let projects = loginData.projects as Project[];

    const renderButtons = (p) => (
        <React.Fragment>
            <ConnectMechanic id={p.id} />
            <EditProject id={p.id} name={p.name} about={p.about} />
        </React.Fragment>
    );

    const sortByMechanicName = (a, b) =>
        a.mechanic.name.localeCompare(b.mechanic.name);

    return (
        <div className='w-full'>
            {projects.map((p) => (
                <ExpandableRow
                    Title={p.name}
                    total={totalProjectHours(p.links)}
                >
                    <div className='flex justify-center'>
                        <ExpendableBox
                            about={p.about}
                            name={p.name}
                            id={p.id}
                            onDelete={() => deleteProjectCall(p, setLoginData)}
                            renderButtons={renderButtons(p)}
                        >
                            <div className='flex flex-col px-10 text-base gap-1'>
                                <div className='text-lg font-bold'>
                                    Monteurs
                                </div>
                                {p.links?.sort(sortByMechanicName).map((x) => (
                                    <LinkView link={x} project={p} />
                                ))}
                            </div>
                        </ExpendableBox>
                    </div>
                </ExpandableRow>
            ))}
        </div>
    );
};
export default projectList;
