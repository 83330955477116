import React, { useContext, useState } from 'react';
import { Project, StateContext } from '../../StateContext';
import {
    Modal,
    Button,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { boxStyle } from './styles';
import { connectMechanicCall } from '../../Services/APICalls/apicalls';
import { IContextData } from '../../../App';

interface PProps {
    id: number;
}

const ConnectMechanic = (props: PProps) => {
    const [open, setOpen] = useState(false);

    const { loginData, setLoginData } = useContext<IContextData>(StateContext);

    const [mechanicId, setMechanicId] = useState(loginData.mechanics[0].id);

    const connectMechanicHandler = () => {
        connectMechanicCall(props.id, mechanicId, setLoginData);
        setOpen(false);
    };

    const handleMechanicChange = (x) => {
        setMechanicId(parseInt(x.target.value));
    };

    return (
        <React.Fragment>
            <Button
                variant='outlined'
                color='success'
                onClick={() => setOpen(true)}
            >
                Monteur koppelen
            </Button>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box style={boxStyle}>
                    <form action='#'>
                        <div className='flex flex-col justify-between items-end'>
                            <FormControl fullWidth>
                                <InputLabel id='mechanic-select-label'>
                                    Monteur
                                </InputLabel>
                                <Select
                                    labelId='mechanic-select-label'
                                    id='mechanic-select'
                                    value={mechanicId}
                                    label='Monteur'
                                    onChange={handleMechanicChange}
                                >
                                    {loginData.mechanics.map((mechanic) => (
                                        <MenuItem
                                            key={mechanic.id}
                                            value={mechanic.id}
                                        >
                                            {mechanic.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <button
                                className='mt-2'
                                onClick={connectMechanicHandler}
                            >
                                Verbinden
                            </button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default ConnectMechanic;
