import { createContext } from 'react';
import { IContextData } from '../App';

export const StateContext = createContext<IContextData>({} as IContextData);

export interface ILoginData {
    user: User;
    projects: Project[];
    mechanics: Mechanic[];
}

export interface Mechanic {
    id: number;
    name: string;
    type: number;
    links: Link[];
    created: Date;
    about: string;
    commonId: number;
    common: Common;
}

export interface Common {
    id: number;
    comments: any[];
}

export interface Link {
    id: number;
    project?: Project;
    projectId: number;
    mechanicId: number;
    weekData: WeekData[];
    weekDataId: number;
    created: Date;
    mechanic?: Project;
}

export interface WeekData {
    id: number;
    weekNumber: number;
    year: number;
    secondsWorked: number;
    created: Date;
}

export interface Project {
    id: number;
    name: string;
    links: Link[];
    todos: any[];
    about: string;
    commonId: number;
    common: Common;
    type?: number;
    created: Date;
}

export interface User {
    id: string;
    userName: string;
    normalizedUserName: string;
    emailConfirmed: boolean;
    passwordHash: string;
    securityStamp: string;
    concurrencyStamp: string;
    phoneNumberConfirmed: boolean;
    twoFactorEnabled: boolean;
    lockoutEnabled: boolean;
    accessFailedCount: number;
}
