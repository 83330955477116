import React, { useContext, useState } from 'react';
import { Link, Mechanic, StateContext } from '../../StateContext';
import './mechanicList.css';
import ExpandableRow from '../../General/ExpandableRow';
import ExpendableBox from '../../General/ExpandableBox';
import { IContextData } from '../../../App';
import {
    deleteLink,
    deleteMechanicCall,
} from '../../Services/APICalls/apicalls';
import { MechanicTypes } from './addMechanic';
import { Button, IconButton } from '@mui/material';
import EditMechanic from './editMechanic';
import ConnectProject from './connectProject';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkView from '../../General/Links/linkView';
import { totalProjectHours } from '../../General/Links/weekView';

const MechanicList = () => {
    const { loginData, setLoginData } = useContext<IContextData>(StateContext);

    let mechanics = loginData.mechanics;

    const renderButtons = (m: Mechanic) => (
        <React.Fragment>
            <ConnectProject id={m.id} />
            <EditMechanic id={m.id} name={m.name} about={m.about} />
        </React.Fragment>
    );

    const handleDeleteLink = (link: Link) => {
        const didConfirm = confirm(
            'Weet je zeker dat je deze koppeling wilt verwijderen?'
        );
        if (didConfirm) {
            deleteLink(link.id, setLoginData);
        }
    };

    const sortByMechanicName = (a, b) => a.name.localeCompare(b.name);

    return (
        <div className='w-100'>
            {mechanics.sort(sortByMechanicName).map((m) => (
                <ExpandableRow Title={m.name}>
                    <div className='flex justify-center w-100'>
                        <ExpendableBox
                            type={MechanicTypes[m.type].label}
                            about={m.about}
                            name={m.name}
                            id={m.id}
                            onDelete={() => deleteMechanicCall(m, setLoginData)}
                            renderButtons={renderButtons(m)}
                        >
                            <div className='flex flex-col px-10 text-base gap-1'>
                                <div className='text-lg font-bold'>
                                    Projecten
                                </div>
                                {m.links.map((x) => (
                                    <LinkView link={x} project={x.project} />
                                ))}
                            </div>
                        </ExpendableBox>
                    </div>
                </ExpandableRow>
            ))}
        </div>
    );
};

export default MechanicList;
