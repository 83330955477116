import React, { useContext, useState } from 'react';
import { Link, StateContext, WeekData } from '../../StateContext';
import EditIcon from '@mui/icons-material/Edit';
import { Modal, Button, Box, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { boxStyle } from '../../Pages/Projects/styles';
import { editWeekDataCall } from '../../Services/APICalls/apicalls';
import { hourToSeconds } from './addWeekOverview';

interface IProps {
    weekData: WeekData;
    linkId: Link;
}

const secondsToHours = (seconds: number) => {
    return seconds / 3600;
};

const EditWeek = (props: IProps) => {
    const [weekNumber, setWeekNumber] = useState(props.weekData.weekNumber);
    const [hoursWorked, setHoursWorked] = useState(
        secondsToHours(props.weekData.secondsWorked)
    );
    const [open, setOpen] = useState(false);

    const { loginData, setLoginData } = useContext<any>(StateContext);

    const editWeekHandler = () => {
        const secondsWorked = hourToSeconds(hoursWorked);
        editWeekDataCall(
            props.linkId.projectId,
            props.weekData.id,
            secondsWorked,
            weekNumber,
            setLoginData
        );
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Tooltip title='Bewerken' arrow>
                <div className='cursor-pointer'>
                    <EditIcon onClick={() => setOpen(true)}></EditIcon>
                </div>
            </Tooltip>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box style={boxStyle}>
                    <form
                        action='#'
                        className='flex flex-col items-center gap-3'
                        onSubmit={editWeekHandler}
                    >
                        <div>
                            <TextField
                                type='number'
                                label='Weeknummer'
                                value={weekNumber}
                                onChange={(x) =>
                                    setWeekNumber(parseInt(x.target.value))
                                }
                            ></TextField>
                        </div>
                        <div>
                            <TextField
                                type='number'
                                label='Uren'
                                value={hoursWorked}
                                inputProps={{
                                    maxLength: 13,
                                    step: '0.01',
                                }}
                                onChange={(x) =>
                                    setHoursWorked(parseFloat(x.target.value))
                                }
                            ></TextField>
                        </div>

                        <button style={{ width: '220px' }}>Wijzigen</button>
                    </form>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default EditWeek;
