import React, { useState } from 'react';
import './expandableBox.css';
import { Button } from '@mui/material';
import { Avatar } from '@mui/material';
import { Link, StateContext } from '../../StateContext';
import DeleteConfirmation from '../DeleteConfirmation';

interface IProps {
    type?: string;
    about: string;
    name: string;
    id: number;
    onDelete: (x: any) => void;
    renderButtons:
        | React.ReactNode
        | React.ReactNode[]
        | React.JSX.Element
        | React.JSX.Element[];
    children?: React.ReactNode | React.ReactNode[];
    link?: Link;
}

const ExpendableBox = (props: IProps) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = (x) => {
        x.stopPropagation();
        setOpen(true);
    };

    const handleClose = (x) => {
        x.stopPropagation();
        setOpen(false);
    };

    return (
        <div className='flex-container'>
            <div className='top-container'>
                <div className='avatar-container'>
                    <Avatar sx={{ width: 100, height: 100 }} alt='Remy Sharp' />
                </div>
                <div className='name-description'>
                    <div className='name-type-container'>
                        <div>{props.name} </div>
                        <div className='type-container'>{props.type}</div>
                    </div>
                    <div className='description'>{props.about}</div>
                </div>
            </div>
            <div>{props.children}</div>
            <div className='button-container'>
                <>
                    <Button
                        variant='outlined'
                        color='error'
                        onClick={handleClickOpen}
                    >
                        <DeleteConfirmation
                            handleDialogAbort={handleClose}
                            handleDialogConfirm={props.onDelete}
                            dialogOpen={open}
                        />
                        Verwijderen
                    </Button>
                </>
                <div className='right-side-buttons'>{props.renderButtons}</div>
            </div>
        </div>
    );
};

export default ExpendableBox;
