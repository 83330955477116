import axios from 'axios';
import { Urls } from './Urls';

export const fetchAllData = (setLoginData) => {
  try {
    axios.get(`${Urls.BaseApiUrl}/Auth`).then((response) => {
      setLoginData(response.data);
    });
  } catch (error) {
    console.log(error, 'fetchAllData');
  }
};
