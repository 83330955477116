import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';

interface IProps {
    onChange: any;
    value: string;
}

const PasswordField = (props: IProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className='relative'>
            <TextField
                id='standard-password-input'
                label='Password'
                type={showPassword ? 'text' : 'password'}
                autoComplete='current-password'
                variant='standard'
                onChange={(x) => {
                    props.onChange(x.target.value);
                }}
                value={props.value}
            />
            <div className='absolute bottom-1 right-2'>
                <IconButton
                    className='show-icon'
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </div>
        </div>
    );
};

export default PasswordField;
