import React, { useState } from 'react';

interface ExpRow {
    Title: string;
    delButton?: React.ReactNode;
    children?: React.ReactNode;
    addWeek?: React.ReactNode;
    total?: number;
}

const ExpandableRow = (props: ExpRow) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div className='w-100 flex justify-center flex-col items-center'>
            <div className='list-item' onClick={() => setExpanded(!expanded)}>
                <div className=''> {props.Title}</div>
                <div className='flex items-center gap-2'>
                    <div className=''>{props.total}</div>
                    {props.addWeek}
                    {props.delButton}
                </div>
            </div>
            {expanded && props.children}
        </div>
    );
};

export default ExpandableRow;
