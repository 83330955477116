import { Box, Button, Modal, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { boxStyle } from '../../Pages/Projects/styles';
import { addWeekCall } from '../../Services/APICalls/apicalls';
import { IContextData } from '../../../App';
import { StateContext, Link } from '../../StateContext';
import { link } from 'fs';

interface IProps {
    link?: Link;
}

export const hourToSeconds = (hoursWorked) => {
    return hoursWorked * 3600;
};

const AddWeekOverview = (props: IProps) => {
    const { loginData, setLoginData } = useContext<IContextData>(StateContext);
    const [mechanicId, setMechanicId] = useState(loginData.mechanics[0].id);
    const [open, setOpen] = useState(false);
    const [weekNumber, setWeekNumber] = useState(0);
    const [hoursWorked, setHoursWorked] = useState(0);

    const addWeekHandler = () => {
        const secondsWorked = hourToSeconds(hoursWorked);
        addWeekCall(props.link.id, weekNumber, secondsWorked, setLoginData);
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                variant='contained'
                color='success'
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
            >
                Week toevoegen
            </Button>
            <Modal
                open={open}
                onClick={(x) => {
                    x.stopPropagation();
                }}
                onClose={() => setOpen(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box style={boxStyle}>
                    <form
                        action='#'
                        onSubmit={addWeekHandler}
                        className='flex flex-col items-center gap-3'
                    >
                        <div>
                            <TextField
                                type='number'
                                label='Weeknummer'
                                onClick={(x) => {
                                    x.stopPropagation();
                                }}
                                onChange={(x) => {
                                    setWeekNumber(parseInt(x.target.value));
                                }}
                            ></TextField>
                        </div>
                        <div>
                            <TextField
                                type='number'
                                label='Uren'
                                inputProps={{
                                    maxLength: 13,
                                    step: '0.01',
                                }}
                                onClick={(x) => {
                                    x.stopPropagation();
                                }}
                                onChange={(x) => {
                                    setHoursWorked(parseFloat(x.target.value));
                                }}
                            ></TextField>
                        </div>

                        <button style={{ width: '220px' }}>Toevoegen</button>
                    </form>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default AddWeekOverview;
