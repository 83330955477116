import React, { useContext, useState } from 'react';
import { Project, StateContext } from '../../StateContext';
import { Modal, Button, Box, TextField } from '@mui/material';
import { boxStyle } from './styles';
import { editProjectCall } from '../../Services/APICalls/apicalls';

interface PProps {
    about: string;
    name: string;
    id: number;
}

const EditProject = (props: PProps) => {
    const [projectName, setProjectName] = useState(props.name);
    const [description, setDescription] = useState(props.about);
    const [open, setOpen] = useState(false);

    const { loginData, setLoginData } = useContext<any>(StateContext);

    const editProjectHandler = () => {
        editProjectCall(props.id, projectName, description, setLoginData);
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant='outlined' onClick={() => setOpen(true)}>
                Wijzigen
            </Button>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box style={boxStyle as any}>
                    <form
                        onSubmit={editProjectHandler}
                        action='#'
                        className='flex flex-col items-center gap-3'
                    >
                        <TextField
                            type='text'
                            variant='filled'
                            label='Project'
                            value={projectName}
                            onClick={(x) => {
                                x.stopPropagation();
                            }}
                            onChange={(x) => setProjectName(x.target.value)}
                        ></TextField>
                        <TextField
                            id='filled-multiline-static'
                            label='Bijzonderheden'
                            multiline
                            rows={4}
                            defaultValue='Type hier bijzonderheden'
                            variant='filled'
                            value={description}
                            style={{ width: '220px' }}
                            onClick={(x) => {
                                x.stopPropagation();
                            }}
                            onChange={(x) => setDescription(x.target.value)}
                        ></TextField>
                        <button style={{ width: '220px' }}>Toevoegen</button>
                    </form>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default EditProject;
