import React, { useContext, useState } from 'react';
import { StateContext } from '../../StateContext';
import './menu.css';
import { PageItems } from '../../Pages';
import { logoutCall } from '../../Services/APICalls/apicalls';

const Menu = () => {
  const { loginData, setLoginData } = useContext(StateContext);
  const { page, setPage } = useContext(StateContext);

  const logoutHandler = () => {
    logoutCall(loginData.user.userName);
    setLoginData(null);
  };

  return (
    <div className='menubar'>
      <div className='button-container-menu'>
        <button onClick={(x) => setPage(PageItems.PROJECTS)}>Projecten</button>
        <button onClick={(x) => setPage(PageItems.MECHANIC)}>Monteurs</button>
      </div>
      <div className='button-container-menu'>
        <button onClick={logoutHandler}>Uitloggen</button>
      </div>
    </div>
  );
};

export default Menu;
