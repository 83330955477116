import React, { useContext, useState } from 'react';
import { StateContext } from '../../StateContext';
import {
    Modal,
    Button,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { boxStyle } from '../Projects/styles';
import { connectMechanicCall } from '../../Services/APICalls/apicalls';
import { IContextData } from '../../../App';
import { type } from 'os';
import { MechanicTypes } from './addMechanic';

interface PProps {
    id: number;
}

const ConnectProject = (props: PProps) => {
    const [open, setOpen] = useState(false);

    const { loginData, setLoginData } = useContext<IContextData>(StateContext);

    const [selectedId, setSelectedId] = useState(loginData.mechanics[0].id);

    const connectMechanicHandler = () => {
        connectMechanicCall(selectedId, props.id, setLoginData);
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                variant='outlined'
                color='success'
                onClick={() => setOpen(true)}
            >
                Verbind project
            </Button>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box style={boxStyle as any}>
                    <form
                        onSubmit={connectMechanicHandler}
                        action='#'
                        className='flex flex-col items-center gap-3'
                    >
                        <FormControl style={{ width: '220px' }}>
                            <InputLabel id='mechanic-type-select-label'>
                                Project
                            </InputLabel>
                            <Select
                                label='project'
                                labelId='project-select-label'
                                id='project-select'
                                value={selectedId}
                                onChange={(x) =>
                                    setSelectedId(
                                        parseInt(x.target.value as string)
                                    )
                                }
                            >
                                {loginData.projects.map((p) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {p.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <button
                            style={{ width: '220px' }}
                            onClick={connectMechanicHandler}
                        >
                            Koppelen
                        </button>
                    </form>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default ConnectProject;
