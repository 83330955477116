import React, { useContext, useState } from 'react';
import { Link, WeekData } from '../../StateContext';
import AddWeekOverview from './addWeekOverview';
import EditWeek from './editWeek';
import { link } from 'fs';

interface IProps {
    link: Link;
}

const secondsToHours = (secondsWorked) => {
    let hours = secondsWorked / 3600;
    return hours;
};

export const totalHours = (weekData: WeekData[]) => {
    let total = 0;
    for (const week of weekData) {
        total += week.secondsWorked;
    }
    return secondsToHours(total);
};

export const totalProjectHours = (links: Link[]) => {
    let total = 0;
    for (const link of links) {
        total += totalHours(link.weekData);
    }
    return total;
};

const totalHoursUpTo = (weekData: WeekData[], upToWeek: WeekData) => {
    let totalHours = 0;
    for (const week of weekData) {
        if (week.weekNumber > upToWeek.weekNumber) {
            continue;
        }
        totalHours += week.secondsWorked;
    }
    return secondsToHours(totalHours);
};

const WeekView = (props: IProps) => {
    console.log(props.link.weekData);

    const sortWeeks = (a: WeekData, b: WeekData) => {
        return a.weekNumber - b.weekNumber;
    };

    const rowClassname = 'flex w-40 justify-center';

    return (
        <div className='flex flex-col gap-3 w-100'>
            <div className='flex flex-row justify-between'>
                <div className={rowClassname}>Week</div>
                <div className={rowClassname}>Uren</div>
                <div className={rowClassname}>Totaal aantal uren</div>
                <div className={rowClassname}></div>
            </div>
            {props.link.weekData.sort(sortWeeks).map((x) => (
                <div className='flex flex-row justify-between  w-100'>
                    <div className={rowClassname}>{x.weekNumber} </div>
                    <div className={rowClassname}>
                        {secondsToHours(x.secondsWorked)}
                    </div>
                    <div className={rowClassname}>
                        {totalHoursUpTo(props.link.weekData, x)}
                    </div>
                    <div className={rowClassname}>
                        <EditWeek weekData={x} linkId={props.link}></EditWeek>
                    </div>
                </div>
            ))}

            {/* <div className='flex justify-end '>
                <AddWeekOverview link={props.link} />
            </div> */}
        </div>
    );
};

export default WeekView;
